export const environment = {
  production: true,
  backend: {
    baseURL: 'https://tipsterapi.meridianbet.com',
    submitTicket: 'https://tipsterapi.meridianbet.com/submit_ticket'
  },
  betshop_id: 1519,
  siteAddress: 'https://meridianbet.me/sr',
  siteRegisterAddress: 'https://meridianbet.me/sr/registracija',
  affiliateSuffix: 'affiliate=230067&campaign=7054&refer=QTk1NzM0QkVFQkE2QjI0NkMwNjEzNENDMjA1QTczQjc=',
  firebase: {
    apiKey: "AIzaSyDaPXXpG6X1_5pN8PI78u6WnmBY5k4O02I",
    authDomain: "tipster-bbe7e.firebaseapp.com",
    projectId: "tipster-bbe7e",
    storageBucket: "tipster-bbe7e.appspot.com",
    messagingSenderId: "627726320423",
    appId: "1:627726320423:web:75649c91dee3e3da209f02",
    measurementId: "G-ZWR4WH0ES0"
  }
};
